<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      color="light-blue"
      icon="mdi-account-tie"
      title="Audit List"
      class="px-5 py-3"
    >
      <v-data-table
        v-if="auditlist"
        :headers="headers"
        :items="audit"
        group-by="id"
        :search="search"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-select
              v-model="select"
              :items="itemsAudit"
              style="margin-top: 20px"
              label="Audit"
              @change="change()"
            />
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.type }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.object_id }}</td>
            <td>{{ item.campo }}</td>
            <td>{{ item.old }}</td>
            <td>{{ item.new }}</td>
            <!-- <td>
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
            </td> -->
          </tr>
        </template>
      </v-data-table>
      <viewAudit
        :edited-item="datos"
        :edited-index="editedIndex"
      />
    </base-material-card>
  </v-container>
</template>

<script>
  import viewAudit from './viewAudit.vue'
  export default {
    name: 'AuditList',
    components: {
      viewAudit,
    },
    data: vm => {
      return {
        search: '',
        select: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        datos: {},
        editedIndex: -1,
        itemsAudit: [
          'Agenda',
          'Categorias',
          'Documentos',
          'Formacion',
          'Intermediacion',
          'Intervenciones',
          'Ofertantes',
          'Ofertas',
          'Usuarias',
          'Usuarios',
        ],
        headers: [
          {
            text: 'Type',
            align: 'left',
            sortable: true,
            value: 'type',
          },
          {
            text: 'Usuario',
            align: 'left',
            sortable: true,
            value: 'username',
          },
          {
            text: 'Id Modif.',
            align: 'left',
            sortable: true,
            value: 'object_id',
          },
          {
            text: 'Campo',
            align: 'left',
            sortable: true,
            value: 'campo',
          },
          {
            text: 'Antiguo Valor',
            align: 'left',
            sortable: true,
            value: 'old',
          },
          {
            text: 'Nuevo Valor',
            align: 'left',
            sortable: true,
            value: 'new',
          },
        //   {
        //     text: 'Acciones',
        //     value: 'acciones',
        //     sortable: false,
        //   },
        ],
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
      }
    },
    computed: {
      auditlist: {
        get () {
          return this.$store.state.audit.auditlist
        },
      },
      audit: {
        get () {
          return this.$store.state.audit.audit
        },
      },
      loading: {
        get () {
          return this.$store.state.audit.loading
        },
      },
    },
    // mounted () {
    //   this.$store.dispatch('usuarios/getUsuarios')
    // },
    methods: {
      editItem (item) {
        this.editedIndex = this.audit.indexOf(item)
        this.datos = Object.assign({}, item)
        this.$store.commit('audit/setRegister', true)
      },
      change () {
        this.$store.dispatch('audit/getAudit', this.select)
      },
    },

  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>

<template>
  <v-card v-if="viewaudit">
    <template>
      <!-- <v-subheader>Datos Personales</v-subheader> -->
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <input
            v-model="editedItem.id"
            type="hidden"
          >
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="editedItem.type"
                label="Type"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="editedItem.username"
                label="Username"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row>
              <v-spacer />
              <v-btn
                color="#F6F6F6"
                style="color:black;"
                @click="volver"
              >
                {{ $t("close") }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-container>
    </template>
  </v-card>
</template>

<script>
  export default {
    name: 'ViewAudit',
    props: {
      editedItem: {
        type: Object,
        required: true,
      },
      editedIndex: {
        type: Number,
        default: 0,
      },
    },
    data: vm => {
      return {
        tab: null,
        valid: true,
        search: '',
        reset: false,
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        edad: [
          v => {
            if (!isNaN(parseFloat(v)) && v >= 0) return true
            return 'Ingresa un número'
          },
        ],
        /* editedItem: {
          id: 0,
          type: '',
          username: '',
        }, */
      }
    },
    computed: {
      viewaudit: {
        get () {
          return this.$store.state.audit.viewaudit
        },
      },

      loading: {
        get () {
          return this.$store.state.audit.loading
        },
      },
    },
    async beforeMount () {
      await this.$store.commit('audit/setRegister', false)
    },
    methods: {
      volver () {
        this.editedIndex = -1
        this.$refs.form.reset()
        this.$store.commit('audit/setRegister', false)
      },
    },
  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-card__title {
  background-color: aliceblue;
}
.expansionPanel {
  width: 100%;
}
.vue-tel-input {
  height: 50px;
  border: none;
  border-bottom: 1px solid #bbb;
}
</style>
